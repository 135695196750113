<template>
  <div class="isPro3">
    <div class="centerBox">
      <div class="title">{{ desc }}</div>
      <div class="img">
        <img :src="img" />
      </div>
    </div>
  </div>
</template>

<script>
import img from "@/assets/Solution/isPro3_c1.png";
export default {
  data() {
    return {
      img: img,
      desc: "闭环平台式医疗场景",
    };
  },
};
</script>

<style scoped>
.isPro3 {
  padding-top: 1.3rem;
  padding-bottom: 1rem;
  background: #fff;
}
.centerBox {
  margin: 0 auto;
  width: 60%;
}

.title {
  text-align: center;
  font-size: 0.3rem;
  position: relative;
  padding-bottom: 0.3rem;
}

.title::after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  width: 0.74rem;
  height: 0.03rem;
  background: #1aa858;
  content: "";
  margin: auto;
}
.img {
  margin-top: 0.3rem;
}

.img img {
  /* max-width: 100%; */
  width: 100%;
  margin: 0 10%;
  display: block;
  margin: auto;
  border: 0;
}

@media screen and (max-width: 1024px) {
  .isPro3 {
    padding: 0.6rem 0;
    background: #fff;
  }
  .title::after {
    width: 1.3rem;
  }
  .centerBox {
    width: 90%;
  }
  .img {
    margin-top: 0.3rem;
    object-fit: contain;
  }
  .title {
    font-size: 0.48rem;
    line-height: 0.67rem;
    padding-bottom: 0.2rem;
  }
}
</style>
