<template>
  <div class="isPro1">
    <!-- pc端 -->
    <div class="isPro1_main">
      <div class="isPro1_title">
        {{ title }}
      </div>
      <ul class="pc">
        <li v-for="(item, index) in list" :key="index">
          <img :src="item.img" />
          <h3>{{ item.desc }}</h3>
        </li>
      </ul>

      <!-- 移动 -->
      <div class="mobile">
        <div class="isswiper_sou1">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="part" v-for="item in list_1" :key="item.key">
                <img :src="item.img" />
                <h3>{{ item.desc }}</h3>
                <!-- <isBorder :hideright="index == 0 ? true : false" /> -->
              </div>
            </div>
            <div class="swiper-slide">
              <div class="part" v-for="item in list_2" :key="item.key">
                <img :src="item.img" />
                <h3>{{ item.desc }}</h3>
                <!-- <isBorder :hideright="index == 0 ? true : false" /> -->
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="isswiper-pagination swiper-pagination"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "@/assets/Solution/isPro1_1.png";
import img2 from "@/assets/Solution/isPro1_2.png";
import img3 from "@/assets/Solution/isPro1_3.png";
import img4 from "@/assets/Solution/isPro1_4.png";
// import isBorder from "@/components/border/index.vue";
export default {
  // components: {
  //   isBorder,
  // },
  data() {
    return {
      title: "聚焦业务痛点 强化医患纽带",

      list_1: [
        {
          img: img1,
          desc: "检前、检中、检后脱节",
          key: "1",
        },
        {
          img: img2,
          desc: "医疗服务触达率低",
          key: "2",
        },
      ],

      list_2: [
        {
          img: img3,
          desc: "医、患粘性低",
          key: "3",
        },
        {
          img: img4,
          desc: "患者管理弱",
          key: "4",
        },
      ],

      list: [],
    };
  },

  mounted() {
    this.list = this.list_1.concat(this.list_2);
    var mySwiper = new Swiper(".isswiper_sou1", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: ".isswiper-pagination",
      },
    });
  },
};
</script>

<style scoped>
.isPro1 {
  padding: 1.2rem 0;
  width: 100%;
}
.isPro1 >>> .swiper-pagination-bullet {
  background: #1aa858;
}

.isPro1_main {
  width: 90%;
  margin: 0 auto;
}

.isPro1_title {
  text-align: center;
  font-size: 0.3rem;
  position: relative;
  padding-bottom: 0.3rem;
}
.isPro1_title::after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 0.74rem;
  height: 0.03rem;
  background: #1aa858;
  content: "";
  margin: auto;
}

.isPro1 ul li {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  border: 1px solid #c4c4c4;
  border-left: none;
  margin-top: 0.7rem;
  height: 3.8rem;
  padding: 1.2rem 0 0 0;
  box-sizing: border-box;
}
.isPro1 ul li:first-child {
  border-left: 1px solid #c4c4c4;
}

.isPro1 ul li img {
  display: block;
  height: 0.8rem;
  margin: auto;
}

.isPro1 ul li h3 {
  text-align: center;
  font-weight: bold;
  font-size: 0.24rem;
  color: #000000;
  margin-top: 0.3rem;
}

.mobile {
  display: none;
}
.part {
  position: relative;
}

@media screen and (max-width: 1024px) {
  .isPro1 {
    padding: 0.5rem 0 0.3rem 0;
    width: 100%;
  }

  .isPro1_title {
    padding: 0 0.1rem 0.2rem;
    font-size: 0.48rem;
    line-height: 0.67rem;
  }

  .isPro1_title::after {
    width: 1.3rem;
  }

  .pc {
    display: none;
  }

  .mobile {
    display: block;
    overflow: hidden;
  }

  .isPro1 ul li {
    width: 50% !important;
  }

  .swiper-slide {
    display: flex;
    padding-bottom: 0.5rem;

    width: 100% !important;
    justify-content: center;
  }

  .swiper-slide div {
    /* flex: 1; */
    min-height: auto;
  }

  .swiper-pagination {
    position: relative;
  }

  .part {
    padding: 0.9rem 0 0.6rem;
    box-sizing: border-box;
    border: 1px solid #c4c4c4;
    margin-top: 0.3rem;
    width: 49% !important;
    overflow: hidden;
    position: relative;
  }

  .part:nth-child(1) {
    border-right: none;
  }

  .part img {
    display: block;
    height: 50px;
    margin: auto;
  }

  .part h3 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 900;
    font-size: 0.14rem;
    line-height: 0.25rem;
    text-align: center;
    margin-top: 0.3rem;
    padding: 0 0.1rem;
    margin-bottom: 0.4rem;
  }
}
</style>