<!-- HealthyManageCore 健康管理中心 --> 
<template>
    <div>
        <isHeader />
        <isBanner :title="title" :desc="desc" :src="src"/>
        <isPro1 v-runshow/>
        <isPro2 v-runshow/>
        <isPro3 v-runshow/>
        <isFooter/>
    </div>
</template>

<script>
    import isHeader from '@/components/header/index.vue';
    import isBanner from '@/components/Solution/isBanner.vue';
    import isPro1 from '@/components/HealthyCore/isPro1.vue';
    import isPro2 from '@/components/HealthyCore/isPro2.vue';
    import isPro3 from '@/components/HealthyCore/isPro3.vue';
    import isFooter from '@/components/footer/index.vue';
    import imgForBanner from '@/assets/Solution/healthyCore_banner.jpg';
    export default {
        name: 'HealthyManageCore',
        data: () => {
            return {
                title: '健康管理中心',
                desc: '一体化建设助力医院提升健康管理业务，借助智慧体检系统和智慧慢病管理平台等产品，协助医院和社区建设健康管理中心',
                src: imgForBanner,
            }
        },
        components: {
            isHeader,
            isBanner,
            isPro1,
            isPro2,
            isPro3,
            isFooter,
        }
    }
</script>

